import React from "react"
import "./MlSolutions.css"
const MlSolutions = () => {
  const industries = [
    {
      title: "Healthcare",
      description: [
        "Predict patient outcomes and enhance diagnostic accuracy with machine learning models.",
        "Improve clinical workflows through automation.",
        "Develop personalized treatment plans using predictive analytics."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-healthcare"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Finance",
      description: [
        "Detect fraud and anomalies in real-time transactions.",
        "Build risk management models for better decision-making.",
        "Optimize investment strategies using predictive algorithms."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-finance"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Retail",
      description: [
        "Create recommendation engines for personalized customer experiences.",
        "Analyze consumer behavior to optimize pricing strategies.",
        "Automate inventory management with demand forecasting models."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-retail"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Manufacturing",
      description: [
        "Implement predictive maintenance to reduce downtime and improve efficiency.",
        "Optimize supply chains with demand forecasting and route optimization.",
        "Analyze production data to improve quality control and reduce costs."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-manufacturing"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },

    {
      title: "Automotive",
      description: [
        "Develop autonomous vehicle systems powered by machine learning.",
        "Optimize fleet management with predictive analytics.",
        "Enhance driver safety and experience through real-time data analysis."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-automotive"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
    {
      title: "Education",
      description: [
        "Personalize learning experiences for students using machine learning algorithms.",
        "Implement automated grading systems and feedback mechanisms.",
        "Analyze student performance and predict future outcomes."
      ],
      icon: (
        <i
          className="icon-machine-learning-development-education"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
    },
  ]

  return (
    <div
      className="min-h-screen flex items-center justify-center text-white bg-cover bg-center relative"
      style={{
        backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2025/02/Industries-We-Serve-with-Machine-Learning-Solutions.webp")`,
      }}
    >
      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>

      <div className="relative z-10 p-6 md:p-16 rounded-lg w-full">
        {/* Title */}
        <h1 className="text-3xl md:text-5xl font-bold my-4 md:mb-6 text-center text-white">
          Industries We Serve with Machine Learning Solutions
        </h1>

        {/* Subtitle */}
        <p className="text-lg md:text-xl text-gray-300 text-center  mx-auto mb-8">
          Machine learning isn't just for tech companies—it’s transforming
          industries across the board. At our Machine Learning Development
          Company, we work with businesses from a range of sectors, offering
          tailored ML Development Services that solve industry-specific
          challenges.
        </p>

        {/* Industry Cards */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 w-full mx-auto gap-4">
          {industries.map((industry, index) => (
            <div className="feature-tagMl">
              <div className="flex flex-col items-start h-full">
                <div
                  className="w-20 h-20 md:w-24 md:h-24 lg:w-26 lg:h-26 rounded-2xl text-white flex items-center justify-center mb-6 text-2xl md:text-4xl lg:text-6xl"
                  style={{
                    background: "rgba(255, 255, 255, 0.10)",
                  }}
                >
                  {industry.icon}
                </div>
                <h3 className="text-xl lg:text-2xl font-bold text-white mb-4">
                  {industry.title}
                </h3>
                <div className="text-left font-normal text-lg text-white overflow-hidden">
                <ul className="list-disc pl-5 space-y-2">
                  {industry.description.map((point, idx) => (
                    <li key={idx} className="text-lg text-gray-200">
                      {point}
                    </li>
                  ))}
                </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default MlSolutions
